<template>
  <b-card>
    <b-row v-show="books.length > 0 || search != null">
      <b-col md="7">
          <b-row>
            <b-col md="4">
              <b-form-group
                label="Exibir"
                label-for="h-exibir"
                label-cols-md="4"
                class="text-muted"
              >
                <b-form-select
                  size="sm"
                  class="form-content"
                  v-model="perPage"
                  :options="perPageOptions"
                  :disabled="isLoading"
                />
              </b-form-group>
            </b-col>
          </b-row>
      </b-col>
      <div class="col-md">
        <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Pesquisar"
               v-model="search"
              debounce="800"
              type="search"
              size="sm"
              :disabled="isLoading"
            />
          </b-input-group>
      </div>
      <b-col md="2">
        <b-button variant="relief-primary" :to="{ name: 'books-create'}" size="sm" block @click="clearBook">
          Novo Livro
          <feather-icon icon="BookIcon" />
        </b-button>
      </b-col>
    </b-row>
    <div v-show="books.length > 0 || search != null">
      <b-overlay :show="isLoading" opacity="0.70" variant="transparent" rounded="sm">
        <div>
          <b-table-simple
            striped
            responsive
            class="mt-2 text-center"
            id="tableBook"
          >
            <b-thead>
              <b-tr class="text-center">
                <b-th>Nome</b-th>
                <b-th>Tipo</b-th>
                <b-th>Data de cadastro</b-th>
                <b-th>Ações</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr :key="indextr" v-for="(tr, indextr) in books">
                <b-td>
                  {{tr.name}}
                </b-td>
                <b-td>
                  {{tr.type === 'teacher' ? "Professor" : "Aluno"}}
                </b-td>
                <b-td>
                  {{tr.created_at |dateFormat}}
                </b-td>
                <b-td>
                  <b-button 
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-dark"
                    class="btn-icon rounded-circle"
                    v-b-toggle.sidebar-clone-class
                    @click="cloneBook(tr.id)"
                  >
                    <feather-icon icon="CopyIcon" />
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-dark"
                    class="btn-icon rounded-circle"
                    @click="toRouterDetails(tr.id)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-dark"
                    class="btn-icon rounded-circle"
                    v-b-toggle.sidebar-link-class
                    @click="saveBook(tr)"
                  >
                    <feather-icon icon="LinkIcon" />
                  </b-button>

                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>

          <div class="row" v-show="totalRows > 10">
            <div class="col-6 col-md-9"> 
              Exibindo {{ perPage }} de {{ totalRows }} registros
            </div>
            <div class="col-6 col-md">
              <b-pagination
                v-model="currentPage"
                class="text-center float-right"
                :per-page="perPage"
                :total-rows="totalRows"
                aria-controls="tableSchools"
                @change="handlePageChange"
                size="sm"
              />
            </div>
          </div>
        </div>
      </b-overlay> 
    </div>

    <div class="text-center" v-show="books.length == 0 && search == null">
      <b-overlay :show="isLoading" opacity="0.70" variant="transparent" rounded="sm">
        <no-data />
        <b-button variant="relief-primary" :to="{ name: 'books-create'}" size="sm" @click="clearBook">
          Novo Livro
          <feather-icon icon="BookIcon" />
        </b-button>
      </b-overlay>
    </div>
    
    <!-- Sidebar clone book -->

    <b-sidebar
      id="sidebar-clone-class"
      shadow
      right
      bg-variant="white"
      backdrop
      title="Duplicar Aula"
      ref="sidebar-clone-class"
    >
      <div>
        <div>
          <small class="text-muted ml-1">
            Selecione os campos que serão duplicados:
          </small>
          <hr>
        </div>
        <!-- checkout  clone -->
        <b-form-checkbox
          value="1"
          class="ml-1 mt-1"
          v-model="cloneClass.lessons"
        >
          Aulas
        </b-form-checkbox>
        <b-form-checkbox
          value="1"
          class="ml-1 mt-1"
          v-model="cloneClass.activities"
        >
          Atividades
        </b-form-checkbox>
        <b-form-checkbox
          value="1"
          class="ml-1 mt-1"
          v-model="cloneClass.support_materials"
        >
          Material de apoio
        </b-form-checkbox>
      </div>
        <b-row class="p-1 mt-2">
          <b-col class="mr-1">
            <!-- Save -->
            <b-button variant="relief-primary" @click="cloneBookFinish">
              Duplicar
            </b-button>
          </b-col>
        </b-row>
    </b-sidebar>

    <b-sidebar
      v-if="bookSelected != null"
      id="sidebar-link-class"
      shadow
      right
      bg-variant="white"
      backdrop
      title="Associar livro"
      ref="sidebar-link-class"
    >
      <div>
        <div>
          <small class="text-muted ml-1">
            Selecione o livro que será associado a este:
          </small>
          <p>
            <strong>
              <center>
                {{ bookSelected.name == null ? '' : bookSelected.name }}
                -  {{ bookSelected.type == 'teacher' ? "Professor" : "Aluno" }}
              </center>
             
            </strong>
          </p>
         
          <hr>
        </div>
        <div class="mr-1 ml-1">
          <small class="text-muted ml-1">
            Selecione o livro do {{ bookSelected.type == 'teacher' ? "Aluno" : "Professor" }}:
          </small>
          <b-form-select
            size="sm"
            value-field="id"
            text-field="name"
            v-model="associatedBookSelected"
            :options="booksList"
          />
        </div>
      </div>
      <b-row class="p-1 mt-2">
        <b-col class="mr-1">
          <b-button variant="relief-primary" @click="storeAssociateds()">
            Associar
          </b-button>
        </b-col>
      </b-row>
    </b-sidebar>
  </b-card>
</template>
<script>
/* eslint-disable */
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import noData from "@/components/systemWarning/NoData.vue";

export default {
  components: {
    BCard,
    noData
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,
      isLoading: true,
      dataBook: null,

      bookSelected: null,
      bookSelectedType: null,
      bookSelectedId: null,
      associatedBookSelected: null,

      cloneClass: {
        book_id: null,
        lessons: 0,
        activities: 0,
        support_materials: 0
      },

      perPageOptions: [
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 20, text: "20" },
      ],
    };
  },
  computed: {
    ...mapState("books", ["books", "booksList"])
  },
  async mounted() {
    this.dataBook = localStorage.book
    if(this.dataBook ){
         this.$router.push("/livros/novo")
      }
  },
  methods: {

     ...mapActions("books", ["copyBook", "storeAssociated"]),

    cloneBook(val){
      this.cloneClass.book_id = val
    },
    toRouterDetails(id){
      localStorage.removeItem('book_id')
      localStorage.setItem(
        "book_id", id
      )
      this.$router.push("/livro/detalhe/" +id)
    },
    cloneBookFinish(){
      this.copyBook({
        ...this.cloneClass
      })
      .then((val)=>{
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Livro clonado com sucesso',
            icon: 'BookIcon',
            text: 'Operação executada com sucesso.',
            variant: 'success'
          },
        })
        this.$refs['sidebar-clone-class'].hide()
      })
    },

    saveBook(data){
      this.bookSelected = data
      this.bookSelectedId = data.id
      this.bookSelectedType = data.type
      this.fetchBooksSelected()
    },

    fetchBooks(paginated = true, page = 1){
      this.isLoading = true
      this.$store
        .dispatch("books/fetchBooks", {
          paginate: paginated,
          page: page,
          generic: this.search,
          per_page: this.perPage,
          class_id: this.$route.params.class_id
        })
        .then((response) => {
          this.totalRows = response.total
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    fetchBooksSelected(){
      this.isLoading = true
      this.$store
        .dispatch("books/fetchBooksShort", {
          paginate: false,
          type: this.bookSelectedType == 'teacher' ? 'student' :'teacher',
          page: 1,
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    storeAssociateds() {
      let data = {
        "teacher_book_id": this.bookSelectedType == "teacher" ? this.bookSelectedId : this.associatedBookSelected,
        "student_book_id": this.bookSelectedType == "student" ? this.bookSelectedId : this.associatedBookSelected
      }
      this.storeAssociated({
        ...data
      }).then((response) => {

        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.data,
            icon: 'ThumbsUpIcon',
            text: 'Operação executada com sucesso.',
            variant: 'success'
          }
        })
        this.bookSelected = null;
        this.bookSelectedType = null;
        this.bookSelectedId = null;
        this.associatedBookSelected = null;
      })
      .finally(() => {
        this.$refs['sidebar-link-class'].hide();
        this.fetchBooks();
      })
    },

    clearBook(){
      localStorage.removeItem("book");
    },

    handlePageChange(value) {
      this.fetchBooks(true, value);
    },
  },
  created() {
    this.fetchBooks()
  },
  watch: {
    perPage(){
      this.fetchBooks()
    },
    search(){
      this.currentPage = 1;
      this.fetchBooks(true, 1);
    }
  },
};
</script>
<style lang="scss">
  .form-content{
    width: 5rem;

  }
  .form-content-search{
    width: 11rem;
  }
</style>
